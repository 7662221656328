const useRefState = (key: string) => {
  const refState = useState<HTMLElement|undefined>('refs-' + key, () => undefined)

  const addOrUpdateRef = (newRef: HTMLElement) => {
    refState.value = newRef
  }

  return {
    addOrUpdateRef,
    ref: readonly(refState)
  }
}

export default useRefState
